import { Row, Col } from 'react-grid-system'
import React from 'react';
import './form.css';
import RestApi from '../../services/rest';
import store from '../../store'
import { connect } from "react-redux";
import { SubAccountBalanceEvent } from '../../models/SubAccountBalanceEvent';
import { SegmentedControl, Segment } from '@pdsreact/segmented-control';
import LoadingIcon from '../loading-spinner/loading-spinner';
import { Button } from '@pdsreact/button';
import moment from 'moment';
import { resolve } from 'path';
import { hotjar } from 'react-hotjar';



class Form extends React.Component<any, any> {
    
    state: any = {};
    rest: RestApi = new RestApi();

    eventTypeOptions: any = [
        { label: 'Price', value: 'price' },
        { label: 'Dividend', value: 'dividend' },
        { label: 'Vesting', value: 'vesting' },
        { label: 'Transaction', value: 'transaction' },
        { label: 'Other', value: 'other' }
    ];

    disabledAccountDropdown: boolean = true;
    disabledEventDropdown: boolean = true;
    disabledMoneyTypeDropdown: boolean = true;
    displayAccountLoadingSpinner: boolean = false;
    displayEmptyContractIdMessage: boolean = false;
    constructor(props: any) {
        super(props);
        //console.log(this.props)
        this.state = {
            contractId: this.props.contractId === undefined ? '' : this.props.contractId,
            enterpriseId: this.props.enterpriseId === undefined ? '' : this.props.enterpriseId,
            accountId: this.props.accountsSelected === undefined ? '' : this.props.accountsSelected[0]
        }

        this.findAccountsByEmpIdAndContract = this.findAccountsByEmpIdAndContract.bind(this);
        this.queryForBalanceEvents = this.queryForBalanceEvents.bind(this);
        this.resetSearchCriteria = this.resetSearchCriteria.bind(this);
        this.enterpriseIdChanged = this.enterpriseIdChanged.bind(this);
        this.contractIdChanged = this.contractIdChanged.bind(this);
    }

    async componentDidMount() {
        //this.rest.retrieveToken();
        // Determines if we can bypass the manual entry.
        //console.log(this.props)
        if (this.validateInput()) {
            await this.findAccountsByEmpIdAndContract();
            this.queryForBalanceEvents();
        } else {

        }
        //hotjar.initialize(3427988, 6);
        
    }

    

    enterpriseIdTimeout: any = null;
    contractIdTimeout: any = null;

    enterpriseIdChanged(event: any): void {
        store.dispatch({
            type: 'UPDATE_ENTERPRISEID',
            payload: event.target.value
        })

        this.setState({ [event.target.name]: event.target.value });
        //console.log(store.getState());
        clearTimeout(this.enterpriseIdTimeout);
        var $this = this;
        this.enterpriseIdTimeout = setTimeout(function () {
            //console.log($this.state);
            if ($this.state.contractId.length == 6 && $this.state.enterpriseId.length > 0) {
                $this.findAccountsByEmpIdAndContract();
            }
        }, 500);
    }

    contractIdChanged(event: any): void {
        store.dispatch({
            type: 'UPDATE_CONTRACTID',
            payload: event.target.value
        })

        this.setState({ [event.target.name]: event.target.value });
        clearTimeout(this.contractIdTimeout);
        var $this = this;
        this.contractIdTimeout = setTimeout(function () {
            if ($this.state.contractId.length === 6 && $this.state.enterpriseId.length > 0) {
                $this.findAccountsByEmpIdAndContract();
            }
        }, 500);
    }

    handleStartDateChange(date: any) {
        //console.log('start date -> ' + date.target.value)
        store.dispatch({
            type: 'SET_START_DATE',
            payload: date.target.value
        })

    }

    handleEndDateChange(date: any) {
        //console.log('end date -> ' + date.target.value)
        store.dispatch({
            type: 'SET_END_DATE',
            payload: date.target.value
        })
    }

    handleDateSliderChange(event: any) {
        const value = event.target.value;
        let startDate, endDate;

        switch (value) {
            case 'allTime':
                startDate = moment('1900-01-01');
                endDate = moment();
                break;
            case 'lastMonth':
                startDate = moment().subtract(1, 'month').startOf('month');
                endDate = moment().subtract(1, 'month').endOf('month');
                break;
            case 'lastYear':
                startDate = moment().subtract(1, 'year').startOf('year');
                endDate = moment().subtract(1, 'year').endOf('year');
                break;
            default:
                startDate = moment('1900-01-01');
                endDate = moment();
                break;
        }
        store.dispatch({
            type: 'SET_START_DATE',
            payload: startDate.format('YYYY-MM-DD')
        })
        store.dispatch({
            type: 'SET_END_DATE',
            payload: endDate.format('YYYY-MM-DD')
        })

        // console.log('Start Date:', startDate.format('YYYY-MM-DD'));
        // console.log('End Date:', endDate.format('YYYY-MM-DD'));
    }

    async findAccountsByEmpIdAndContract() {
        return new Promise((resolve, reject) => {
            if (this.state.contractId !== '' && this.state.enterpriseId !== '') {
                store.dispatch({
                    type: 'SET_EMPLOYEE_DATA',
                    payload: [this.state.contractId, this.state.enterpriseId]
                })
                store.dispatch({
                    type: 'ENABLE_LOADING_SPINNER'
                })

                //This get request takes contractid and enterpriseid and returns information about the participant in the contract.            
                //we're going to need mt ids for the contract and the accounts for the participant            
                // fill out the rest of the form with an on prem call to retrieve account and MT IDs               
                this.rest.getAccounts(this.props.contractId, this.props.enterpriseId).then(
                    (response: any) => {

                        let accountSelected = this.state.accountId;

                        let accountsSelected: any[] = []
                        console.log()
                        let accounts = response.data;
                        accounts.filter((data: any) => {
                            if (data.accountId === accountSelected) {
                                accountsSelected[0] = {
                                    value: data.accountId,
                                    label: '(' + data.accountId + ') ' + data.bucketName,
                                    name: data.bucketName,
                                }
                            }
                        })
                        store.dispatch({
                            type: 'SET_ACCOUNT_OPTIONS_SELECTED',
                            payload: accountsSelected
                        })


                        //  Set accountSelected to the proper accountIDs index.
                        //  Then send a dispatch to 'SET_ACCOUNT_OPTIONS_SELECTED'
                        //console.log(this.props.accountsSelected)
                        //console.log(response);
                        store.dispatch({
                            type: 'SET_EMPLOYEE_ACCOUNTS',
                            payload: response.data
                        });
                        store.dispatch({
                            type: 'SET_EMPLOYEE_NAME',
                            payload: this.determineEmployeeName(response.data)
                        });
                        store.dispatch({
                            type: 'DISABLE_LOADING_SPINNER'
                        });
                        store.dispatch({
                            type: 'ENABLE_SEARCH_BUTTON'
                        })
                        resolve(response)
                    }, (error: any) => {
                        console.log(error);
                        store.dispatch({
                            type: 'DISABLE_LOADING_SPINNER',
                        });
                        reject(error)
                    });

                this.rest.getMoneyTypes(this.props.contractId).then(
                    (response: any) => {
                        //console.log(response);
                        this.enableDropdowns();
                        store.dispatch({
                            type: 'SET_CONTRACT_MONEYTYPES',
                            payload: response.data
                        });
                        store.dispatch({
                            type: 'DISABLE_LOADING_SPINNER',
                        });
                        store.dispatch({
                            type: 'ENABLE_SEARCH_BUTTON'
                        })
                        resolve(response)
                    }, (error: any) => {
                        console.log(error);
                        store.dispatch({
                            type: 'DISABLE_LOADING_SPINNER',
                        });
                        reject(error)
                    });
            }
        }
        );

    }

    determineEmployeeName(accounts: any[]): string {
        let empName = '';
        if (accounts.length !== 0) {
            empName = accounts[0].firstName + " " + accounts[0].lastName
        }

        return empName;
    }

    queryForBalanceEvents(): void {
        let events: SubAccountBalanceEvent[] = [];
        //  Check if the events or filtered events table is empty,
        //  We need to refresh the tables so we see the loading table
        //  Upon searching.

        if (this.validateInput()) {
            // The point of this action is so we don't hit the message block when we attempt to 

            // submit another request.
            store.dispatch({
                type: 'SET_BALANCE_EVENTS',
                payload: events
            })
            store.dispatch({
                type: 'SET_NOT_EMPTY_LIST'
            })
            store.dispatch({
                type: 'ENABLE_LOADING_TABLE'
            })
            store.dispatch({
                type: 'SET_ENTRIES_PER_PAGE',
                payload: 10
            })
            store.dispatch({
                type: 'RESET_PAGINATION'
            })
            this.rest.getSubAccountBalances(this.props.contractId, this.props.enterpriseId, this.props.startDate, this.props.endDate)
                .then((res: any) => {
                    events = res.data.items;
                    if (events.length > 0) {
                        events = events.filter((data: SubAccountBalanceEvent) => {
                            return [...this.props.accountIds].some((acc: any) => {
                                if (acc.accountId === data.acntId) {
                                    data.acntName = acc.bucketName;
                                    return true;
                                }
                            })
                        })
                        store.dispatch({
                            type: 'DISABLE_LOADING_TABLE'
                        })
                        store.dispatch({
                            type: 'SET_BALANCE_EVENTS',
                            payload: events
                        })
                        store.dispatch({
                            type: 'DISABLE_REQUIRED_FIELDS'
                        })
                    }
                    else {
                        store.dispatch({
                            type: 'SET_EMPTY_LIST_MESSAGE',
                            payload: "Based upon your search parameters, we cannot find any transaction records for this participant. <br/>You may want to expand your filters or your date range."
                        })
                    }
                }).catch((error: any) => {
                    console.log(error);
                });
        }
    }


    validateInput(): boolean {
        return this.state.contractId.length === 6 && this.state.enterpriseId.length === 9
    }

    checkContractIdLength(): boolean {
        //TODO:  Should we begin checking for [0-9] for contractID
        if (this.state.contractId.length !== 6) {
            this.displayEmptyContractIdMessage = true;
            return false;
        }
        else {
            this.displayEmptyContractIdMessage = false;
            return true;
        }
    }

    resetSearchCriteria(): void {
        this.state.enterpriseId = '';
        this.state.contractId = '';
        this.disabledAccountDropdown = true;
        this.disabledEventDropdown = true;
        this.disabledMoneyTypeDropdown = true;

        store.dispatch({
            type: 'RESET_SEARCH'
        })
        store.dispatch({
            type: 'DISABLE_SEARCH_BUTTON'
        })
        store.dispatch({
            type: 'DISABLE_LOADING_TABLE'
        })
    }

    enableDropdowns(): void {
        this.displayEmptyContractIdMessage = false;
        this.disabledAccountDropdown = false;
        this.disabledEventDropdown = false;
        this.disabledMoneyTypeDropdown = false;
        this.disabledAccountDropdown = false;
    }

    handleChangeAccount = (selected: any) => {
        console.log(selected);
        store.dispatch({
            type: 'SET_ACCOUNT_OPTIONS_SELECTED',
            payload: selected
        })
    };

    handleChangeEvent = (selected: any) => {
        store.dispatch({
            type: 'SET_EVENT_OPTIONS_SELECTED',
            payload: selected
        })
    };

    handleChangeMoneyType(selected: any) {
        store.dispatch({
            type: 'SET_MT_OPTIONS_SELECTED',
            payload: selected
        })
    }


    updateFilteredEvents() {
        let filteredEvents = [...this.props.events];
        let eventTypesSelected = [...this.props.eventTypesSelected];
        if (eventTypesSelected.length !== 0) {
            //TODO make this actually performant
            filteredEvents = filteredEvents.filter((event: SubAccountBalanceEvent) => {
                eventTypesSelected.includes(event.evntType)
            });
        }
    }


    render() {
        // const moneyTypeOptions = this.props.moneyTypeIds.map((item: any) => ({ value: item.mtid, label: '(' + item.mtid + ') ' + item.moneyName }));
        // const accountOptions = this.props.accountIds.map((item: any) => ({ value: item.accountId, label: '(' + item.accountId + ') ' + item.bucketName, name: item.bucketName }));
        const displayLoadingSpinner = this.props.loadingSpinner ? <LoadingIcon /> : '';
        //console.log(this.state.enterprsiseId, this.state.contractId)
        return (
            <>

            <div>
                <div id="inputFieldContainer">
                    <p id="inputFieldHeader">View balance and transaction history for a participant.</p>
                    <div id='nqBalanceTable'>
                        <table>
                            <tbody>
                                <tr className="tableRow">
                                    <td className="inputFieldTextRequired">
                                        Contract ID
                                    </td>
                                    <td className="inputFieldTextRequired">
                                        Enterprise ID
                                    </td>
                                </tr>
                                <tr className="tableRow">
                                    <td className="inputField">
                                        <input className="employeeInput" name="contractId" type="text" disabled={this.props.requiredFieldsIsDisabled}
                                            value={this.props.contractId} onChange={this.contractIdChanged}
                                            placeholder="Begin typing..." />
                                    </td>
                                    <td className="inputField">
                                        <input className="employeeInput" name="enterpriseId" type="text" disabled={this.props.requiredFieldsIsDisabled}
                                            value={this.props.enterpriseId} onChange={this.enterpriseIdChanged}
                                            placeholder="Begin typing..." />
                                    </td>
                                    <td className="inputField">
                                        {displayLoadingSpinner}
                                    </td>
                                    <td>

                                    </td>
                                    <td>
                                    </td>
                                </tr>
                                <tr className="tableRow">
                                    <td className="inputFieldText">
                                        <p></p>
                                    </td>
                                </tr>
                                <tr className="tableRow">
                                    <td className="inputFieldText">
                                        View results from
                                    </td>
                                    <td className="inputFieldText">
                                        Start date (Optional)
                                    </td>
                                    <td className="inputFieldText">
                                        End date (Optional)
                                    </td>
                                </tr>
                                <tr className="tableRow">
                                    <td>
                                        <SegmentedControl name="triple-segmented-control" disabled={this.disabledAccountDropdown}>
                                            <Segment
                                                defaultChecked
                                                id="triple-segmented-control-one"
                                                label="All Time"
                                                value="allTime"
                                                onClick={this.handleDateSliderChange}
                                            />
                                            <Segment
                                                id="triple-segmented-control-two"
                                                label="Last Month"
                                                value="lastMonth"
                                                onClick={this.handleDateSliderChange}
                                            />
                                            <Segment
                                                id="triple-segmented-control-three"
                                                label="Last Year"
                                                value="lastYear"
                                                onClick={this.handleDateSliderChange}
                                            />
                                        </SegmentedControl>
                                    </td>
                                    <td className="inputField">
                                        <div id="datePicker" className="pds-datepicker" data-label="Date">
                                            <input style={{ height: '70px' }} disabled={this.disabledAccountDropdown} placeholder="MM/DD/YYYY" type="date" name="date" value={this.props.startDate} onChange={this.handleStartDateChange} />
                                        </div>
                                        &nbsp;
                                    </td>
                                    <td className="inputField">
                                        <div id="datePicker" className="pds-datepicker" data-label="Date">
                                            <input style={{ height: '70px' }} disabled={this.disabledAccountDropdown} placeholder="MM/DD/YYYY" type="date" name="date" value={this.props.endDate} onChange={this.handleEndDateChange} />
                                        </div>
                                        &nbsp;
                                    </td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                    <td>&nbsp;</td>
                                </tr>


                                <tr className="tableRow">
                                    <td className="inputField">
                                        <div id="submitRow ">
                                            <Row>
                                                <Col md={5}> <div id="submitContainer">

                                                    <Button id="MyFancyPDSReactPrimaryBtn" variant="primary"
                                                        disabled={this.props.disabledSearchButton} onClick={this.queryForBalanceEvents}>
                                                        Search
                                                    </Button>
                                                </div>
                                                </Col>
                                                <Col lg={5}>
                                                    <p id="resetSearch" className="pds-typography-bold" onClick={this.resetSearchCriteria}>Reset search</p>
                                                </Col>
                                            </Row>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                    </div>
                </div>
            </div>
            </>
        )
    }
}

function mapStateToProps(state: any) {
    return {
        contractId: state.rootReducer.balReducer.contractId,
        enterpriseId: state.rootReducer.balReducer.enterpriseId,
        accountIds: state.rootReducer.balReducer.accounts,
        moneyTypeIds: state.rootReducer.balReducer.moneyTypes,
        moneyTypeIdFilters: state.rootReducer.balReducer.moneyTypeFilters,
        isEmptyList: state.rootReducer.balReducer.isEmptyList,
        displayDataTable: state.rootReducer.balReducer.displayDataTable,
        startDate: state.rootReducer.balReducer.startDate,
        endDate: state.rootReducer.balReducer.endDate,
        loadingSpinner: state.rootReducer.balReducer.loadingSpinner,
        loadingTable: state.rootReducer.balReducer.loadingTable,
        disabledSearchButton: state.rootReducer.balReducer.disabledSearchButton,
        accountsSelected: state.rootReducer.balReducer.accountsSelected,
        eventTypesSelected: state.rootReducer.balReducer.eventTypesSelected,
        moneyTypesSelected: state.rootReducer.balReducer.moneyTypesSelected,
        requiredFieldsIsDisabled: state.rootReducer.balReducer.requiredFieldsIsDisabled,
        events: state.rootReducer.balReducer.events,
        filteredEvents: state.rootReducer.balReducer.filteredEvents
    };
}

export default connect(mapStateToProps)(Form);

