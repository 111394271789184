const initialState = {
   authToken: '',
   awsForgerockToken: ''
}

export default function authenticationReducer(state = initialState, action: any) {
   switch (action.type) {
      case 'SET_NEW_AUTH_TOKEN': {
         return {
            ...state,
            authToken: action.payload
         }
      }

      case 'SET_AWS_FORGEROCK_TOKEN': {
         return {
            ...state,
            awsForgerockToken: action.payload
         }
      }
      default:
         return state
   }
}