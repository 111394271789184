//import { AnimatedIcon } from "@pds-react/animatedIcon";
import React from "react";
import { LoadingSpinner } from '@pdsreact/loading-spinner';
import './loading-spinner.css';

class EventDetailLoadingIcon extends React.Component<any, any> {

   render() {
      return (
         <div>
            <LoadingSpinner aria-label="Loading" id="eventDetailLoadingSpinner" />
         </div>
      )
   }
}

export default EventDetailLoadingIcon;