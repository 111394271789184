import './table.css';
import React from 'react';
import RestApi from '../../services/rest';
import { SubAccountBalanceEvent } from '../../models/SubAccountBalanceEvent';
import { connect } from "react-redux";
import Icon from '@pds-react/icon';
import Select from '@pds-react/select';
import store from '../../store';
import Message from "../message/message";
import Button from '@pds-react/button';
import ParticipantInformation from '../participant-information/participant-information';
import ReactSelect from 'react-select';
import { format } from 'date-fns';
import { render } from 'react-dom';
import EventDetailsModal from '../event-details/event-details-modal';
import EventDetailLoadingIcon from '../loading-spinner/event-detail-loading-spinner';


class Pagination extends React.Component<any, any> {
    rest: RestApi = new RestApi();

    constructor(props: any) {
        super(props);
        this.state = {
            sortOrder: "ascending",
            displayEventDetailLoadingIcon: false,
            eventDetailComponentIsVisible: false,
            eventDetailId: null,
            eventDetail: null,
            eventType: null
        }
    }

    eventTypeOptions: any = [
        { label: 'Price', value: 'price' },
        { label: 'Dividend', value: 'dividend' },
        { label: 'Vesting', value: 'vesting' },
        { label: 'Transaction', value: 'transaction' },
        { label: 'Other', value: 'other' }
    ];

    disabledAccountDropdown: boolean = true;
    disabledEventDropdown: boolean = true;
    disabledMoneyTypeDropdown: boolean = true;

    // handleSort = (filteredData : SubAccountBalanceEvent[]) : void => {
    //     filteredData = filteredData.sort((a: any, b: any) => {
    //         if (a.efctDte < b.efctDte) {
    //             return -1;
    //         }
    //         if (a.efctDte > b.efctDte) {
    //             return 1;
    //         }
    //         return 0;
    //     });

    //     //return filteredData;

    //     // Reverse the order if already sorted by ascending
    //     if (this.state.sortOrder === 'ascending') {
    //         filteredData = filteredData.reverse();

    //     }

    //     this.setState({
    //         filteredData,
    //         sortOrder: this.state.sortOrder === 'ascending' ? 'descending' : 'ascending'
    //     });

    //     //console.log('the table is sorted in ' + this.state.sortOrder + ' order.')

    //     //console.log(filteredData)
    //     store.dispatch({ type: 'SET_FILTERED_EVENTS', payload: filteredData });
    // };

    handleEntriesPerPage = (event: any) => {
        const entriesPerPage = event.target.value;
        const totalPages = Math.ceil(this.props.events.length / entriesPerPage);
        //console.log(`Total pages: ${totalPages}`);
        store.dispatch({
            type: 'SET_ENTRIES_PER_PAGE',
            payload: entriesPerPage
        });
        store.dispatch({
            type: 'SET_TOTAL_PAGES',
            payload: totalPages
        });
        store.dispatch({
            type: 'CHANGE_PAGE',
            payload: 1
        })
    }

    handlePageChange = (newPage: any) => {
        if (typeof newPage === "string") {
            if (newPage === 'prev' && this.props.currentPage > 1) {
                //console.log('prev: ' + this.props.currentPage);
                store.dispatch({
                    type: 'CHANGE_PAGE',
                    payload: this.props.currentPage - 1
                })
            } else if (newPage === 'next' && this.props.currentPage < this.props.totalPages) {

                store.dispatch({
                    type: 'CHANGE_PAGE',
                    payload: this.props.currentPage + 1
                })
            }
            else if (newPage === 'first') {
                store.dispatch({
                    type: 'CHANGE_PAGE',
                    payload: 1
                })

            }
            else if (newPage === 'last') {
                store.dispatch({
                    type: 'CHANGE_PAGE',
                    payload: this.props.totalPagesUpdated
                })
            }
        }
        else if (typeof newPage === "number") {
            if (newPage <= 1 || this.props.currentPage <= 2) {
                store.dispatch({
                    type: 'CHANGE_PAGE',
                    payload: 1
                })
            }
            if (newPage < this.props.currentPage) {
                store.dispatch({
                    type: 'CHANGE_PAGE',
                    payload: newPage
                })

            } else {
                store.dispatch({
                    type: 'CHANGE_PAGE',
                    payload: newPage
                })
            }

            if (newPage > this.props.totalPages) {
                store.dispatch({
                    type: 'CHANGE_PAGE',
                    payload: this.props.totalPages
                })
            }
        } else {return; }

    }

    handleChangeAccount = (selected: any) => {
        console.log(selected);
        store.dispatch({
            type: 'SET_ACCOUNT_OPTIONS_SELECTED',
            payload: selected
        })
    };

    handleChangeEvent = (selected: any) => {
        store.dispatch({
            type: 'SET_EVENT_OPTIONS_SELECTED',
            payload: selected
        })
    };

    handleChangeMoneyType(selected: any) {
        store.dispatch({
            type: 'SET_MT_OPTIONS_SELECTED',
            payload: selected
        })
    }

    updateFilteredEvents() {
        let filteredEvents = [...this.props.events];
        let eventTypesSelected = [...this.props.eventTypesSelected];
        if (eventTypesSelected.length !== 0) {
            //TODO make this actually performant
            filteredEvents = filteredEvents.filter((event: SubAccountBalanceEvent) => {
                eventTypesSelected.includes(event.evntType)
            });
        }
    }



    displayFilterOptions(filteredData: SubAccountBalanceEvent[]): any {
        //console.log(this.props.accountsSelected)
        const moneyTypeOptions = this.props.moneyTypeIds.map((item: any) => ({ value: item.mtid, label: '(' + item.mtid + ') ' + item.moneyName }));
        const accountOptions = this.props.accountIds.map((item: any) => ({ value: item.accountId, label: '(' + item.accountId + ') ' + item.bucketName, name: item.bucketName }));
        let endCashBalNumber;
        let roundedEndCashBal;
        let formattedDate;
        let currentTime;
        if (filteredData.length > 0) {
            endCashBalNumber = Number(filteredData[0].endCashBal);
            roundedEndCashBal = endCashBalNumber.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
            const dateStr = filteredData[0].efctDte;
            const dateObj = new Date(dateStr);
            formattedDate = format(dateObj, 'MMMM d, yyyy');
            currentTime = format(new Date(), 'h:mm:ss a');
        }
        const enterpriseId = this.props.displayEnterpriseId === true ? this.props.enterpriseId : '';
        const contractId = this.props.displayContractId === true ? this.props.contractId : '';
        const employeeName = this.props.displayEmployeeName === true ? this.props.employeeName : '';
        const displayCurrentBalanceElement = filteredData.length > 0 ? <div id="searchResults">
            Current balance as of {formattedDate} at {currentTime}
            <h2><b>${roundedEndCashBal}</b></h2>
        </div> : '';
        return (
            <div >
                {<ParticipantInformation />}
                {/* <div id="searchResults">
                    Current balance as of {formattedDate} at {currentTime}
                    <h2><b>${roundedEndCashBal}</b></h2>
                </div> */}
                {/* {displayCurrentBalanceElement} */}

                <br />
                <div id="searchResults">
                    <hr className="hrStyle" />
                    <table>
                        <tr className="tableRow">
                            <td className="inputFieldText">
                                <h6>Filter these results</h6>
                            </td>
                        </tr>
                        <br />
                        <tr className="tableRow">
                            <td className="inputFieldText">
                                Bucket
                            </td>
                            <td className="inputFieldText">
                                Event Type
                            </td>
                            <td className="inputFieldText">
                                Money Type
                            </td>
                        </tr>
                        <tr className="tableRow">

                            {<td className="inputField">
                                <div>
                                    <ReactSelect
                                        //isDisabled={this.disabledAccountDropdown}
                                        styles={{
                                            control: (base: any) => ({
                                                ...base,
                                                minHeight: 'auto',
                                                height: '70px',
                                                width: 400,
                                                overflowX: 'hidden',
                                                display: 'flex',
                                                overflowY: 'auto'
                                            })
                                        }}
                                        options={accountOptions}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={this.handleChangeAccount}
                                        //allowSelectAll={true}
                                        value={this.props.accountsSelected}
                                    />
                                </div>
                            </td>}
                            {
                                <td className="inputField">
                                    {<div>
                                        <ReactSelect
                                            //isDisabled={this.disabledEventDropdown}
                                            styles={{
                                                control: (base: any) => ({
                                                    ...base,
                                                    minHeight: 'auto',
                                                    height: '70px',
                                                    width: 400,
                                                    overflowX: 'hidden',
                                                    display: 'flex',
                                                    overflowY: 'auto'

                                                })
                                            }}
                                            className="eventDropDownSelect"
                                            options={this.eventTypeOptions}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            hideSelectedOptions={false}
                                            onChange={this.handleChangeEvent}
                                            //isDisabled={true}
                                            //allowSelectAll={true}
                                            value={this.props.eventTypesSelected}
                                        />
                                    </div>}
                                </td>
                            }
                            <td className="inputField">
                                {<div>
                                    <ReactSelect
                                        //isDisabled={this.disabledMoneyTypeDropdown}
                                        styles={{
                                            control: (base: any) => ({
                                                ...base,
                                                minHeight: 'auto',
                                                height: '70px',
                                                width: 400,
                                                overflowX: 'hidden',
                                                display: 'flex',
                                                overflowY: 'auto'
                                            })
                                        }}
                                        options={moneyTypeOptions}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        hideSelectedOptions={false}
                                        onChange={this.handleChangeMoneyType}
                                        //allowSelectAll={true}
                                        value={this.props.moneyTypesSelected}
                                    />
                                </div>}
                            </td>
                        </tr>
                    </table>
                    <br />
                    <hr className="hrStyle" />
                </div>

                <br />
            </div>
        );
    }
    sortTable(table: any) {

    }

    displayDataTable(filteredData: any): any {
        const currentPage = this.props.currentPage;
        let currentData = filteredData.slice((currentPage - 1) * this.props.entriesPerPage, currentPage * this.props.entriesPerPage);
        let arrayLength = Math.ceil(filteredData.length / this.props.entriesPerPage);

        store.dispatch({
            type: 'SET_UPDATED_TOTAL_PAGES',
            payload: arrayLength
        })
        console.log("CurrentPage: " + this.props.currentPage);
        console.log("Array length: " + arrayLength);
        if (this.props.currentPage > arrayLength) {
            store.dispatch({
                type: 'RESET_PAGINATION'
            })
        }
        let effectiveDateArrow = this.state.sortOrder === 'ascending' ? <span style={{ marginLeft: '4px' }}>▲</span> : <span style={{ marginLeft: '4px' }}>▼</span>;
        if (this.state.sortOrder === 'descending') {
            effectiveDateArrow = <span style={{ marginLeft: '4px' }}>▲</span>;
        } else if (this.state.sortOrder === 'ascending') {
            effectiveDateArrow = <span style={{ marginLeft: '4px' }}>▼</span>;
        }

        return (
            <div id="tablePrimary" className="pds-tableActionMenu pds-tableActionMenu-actionOptions">
                <table className="pds-table pds-table-bordered" id="pds-tableAction-example" aria-live="polite">
                    <thead>
                        <tr>
                            <th className="effectiveDateColumn" scope="col" onClick={() => {
                                this.setState({

                                    sortOrder: this.state.sortOrder === 'ascending' ? 'descending' : 'ascending'
                                });

                            }}>Effective date {effectiveDateArrow}</th>

                            <th scope="col">Time of Change</th>
                            <th scope="col">Bucket </th>
                            <th scope="col">Bucket ID</th>
                            <th scope="col">Event</th>
                            <th scope="col">Money type</th>
                            <th scope="col">Ending cash balance</th>
                            <th id="eventColumn" className="pds-tableActionMenu-actionColumn" scope="col">Event details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            currentData.map((data: SubAccountBalanceEvent) => {
                                const endCashBalNumber = Number(data.endCashBal);
                                var roundedEndCashBal = endCashBalNumber.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                                return (
                                    <tr>
                                        <td className="pds-dataTable-table-item">{data.efctDte}</td>
                                        <td className="pds-dataTable-table-item">{data.lstChgTs}</td>
                                        <td className="pds-dataTable-table-item">{data.acntName}</td>
                                        <td className="pds-dataTable-table-item">{data.acntId}</td>
                                        <td className="pds-dataTable-table-item">{data.evntType}</td>
                                        <td className="pds-dataTable-table-item">{data.mtId}</td>
                                        <td className="pds-dataTable-table-item">${roundedEndCashBal}</td>
                                        <td className="pds-tableActionMenu-actionColumn">
                                            {<Button title={data.evntType != "transaction" ? 'No details available. Please contact IT.' : ''} disabled={data.evntType != "transaction"} className="pds-button pds-button-tertiary-light"
                                                data-modal="pds-modal-event-details" onClick={() => this.getEventDetails(data)}
                                            >View Details</Button>}
                                        </td>

                                    </tr>
                                )
                            })}
                    </tbody>
                </table>
                <div style={{ float: 'right' }}>
                    <nav id="paginationContainer" className="pds-pagination" aria-label="Page navigation example">
                        <ul>
                            <Select
                                id="erroredSelectWithLabel"
                                label=""
                                onChange={this.handleEntriesPerPage}
                                value={this.props.entriesPerPage}
                            >
                                <option value={10}>10</option>
                                <option value={25}>25</option>
                                <option value={50}>50</option>
                            </Select>
                            <p className="dropDownLabel" > &nbsp; of {filteredData.length} Entries &nbsp;</p>
                            <li className="pds-pagination-item"><a className={this.props.currentPage === 1 ? "pds-pagination-link pds-pagination-fly-first disabled" : "pds-pagination-link pds-pagination-fly-first enabled"} href="#" role="link" aria-label="First page" onClick={() => this.handlePageChange('first')}><Icon name="chevrons-left" /></a></li>
                            <li className="pds-pagination-item"><a className={this.props.currentPage === 1 ? "pds-pagination-link pds-pagination-step-forward disabled" : "pds-pagination-link pds-pagination-step-forward enabled"} href="#" role="link" aria-label="Previous page" onClick={() => this.handlePageChange('prev')}><Icon name="chevron-left" /></a></li>

                            {
                                Array.from({ length: arrayLength }, (_, i) => (

                                    <li className="pds-pagination-item"><a className={this.props.currentPage === i + 1 ? "pds-pagination-link pds-pagination-active" : "pds-pagination-link pds-pagination"} href="#" key={i} onClick={() => this.handlePageChange(i + 1)}>
                                        {i + 1}</a></li>)).slice(this.props.currentPage - 1, this.props.currentPage + 3)

                            }
                            <li className="pds-pagination-item"><a className={this.props.currentPage === this.props.totalPagesUpdated ? "pds-pagination-link pds-pagination-fly-first disabled" : "pds-pagination-link pds-pagination-fly-first enabled"} href="#" role="link" aria-label="Next page" onClick={() => this.handlePageChange('next')}><Icon name="chevron-right" /></a></li>
                            <li className="pds-pagination-item"><a className={this.props.currentPage === this.props.totalPagesUpdated ? "pds-pagination-link pds-pagination-step-forward disabled" : "pds-pagination-link pds-pagination-step-forward enabled"} href="#" role="link" aria-label="Last page" onClick={() => this.handlePageChange('last')}><Icon name="chevrons-right" /></a></li>
                        </ul>
                    </nav>
                </div>
                
            </div>
        )
    }

    displayNoDataMessage(): any {

        store.dispatch({
            type: 'SET_EMPTY_LIST_MESSAGE',
            payload: "Based upon your search parameters, we cannot find any transaction records for this participant. You may want to expand your filters or your date range."
        });
        return (<Message />)
    }

    getEventDetails(data: SubAccountBalanceEvent) {
        this.setState({
            displayEventDetailLoadingIcon: true
        })
        this.rest.getEventDetails(data.evntId, data.acntId, data.mtId, data.evntType, data.lstChgTs)
            .then((response: any) => {
                console.log("Response object from API");
                console.log(response)
                this.setState({
                    eventDetailId: response.data.eventId,
                    eventDetail: response.data,
                    eventType: data.evntType,
                    displayEventDetailLoadingIcon: false
                })
            }, (error: any) => {
                console.log(error);
                this.setState({
                    eventDetail: null,
                    eventDetailId: data.evntId,
                    displayEventDetailLoadingIcon: false
                })
                if (error.request.status == 404) {
                    console.log("Received 404 - event not found");
                }
            });

        this.toggleShowModal();
    }

    toggleShowModal() {
        this.setState({
            eventDetailComponentIsVisible: !this.state.eventDetailComponentIsVisible
        })
    }

    setEventDetailId(detailId: any) {
        this.setState({
            eventDetailId: detailId
        })
    }


    render() {


        // Additionally, filter the data based off money type selected.
        let filteredData = [...this.props.events]
        if ([...this.props.moneyTypesSelected].length !== 0) {

            filteredData = filteredData.filter((data: SubAccountBalanceEvent) => {
                return [...this.props.moneyTypesSelected].some((mt: any) => {
                    return mt.value === data.mtId
                })
            })
        }

        if ([...this.props.eventTypesSelected].length !== 0) {

            filteredData = filteredData.filter((data: SubAccountBalanceEvent) => {
                return [...this.props.eventTypesSelected].some((event: any) => {
                    return event.value === data.evntType;
                })
            })
        }

        if (this.props.accountsSelected[0] !== undefined) {

            filteredData = filteredData.filter((data: SubAccountBalanceEvent) => {
                return [...this.props.accountsSelected].some((acc: any) => {
                    if (acc.value === data.acntId) {
                        data.acntName = acc.name;
                        return true;
                    }
                })
            })
        }



        filteredData = filteredData.sort((a: any, b: any) => {
            if (a.efctDte < b.efctDte) {
                return -1;
            }
            if (a.efctDte > b.efctDte) {
                return 1;
            }
            return 0;
        });

        //return filteredData;
        //
        // Reverse the order if already sorted by ascending
        if (this.state.sortOrder === 'ascending') {
            filteredData = filteredData.reverse();
        }

        //console.log(filteredData)

        let data: SubAccountBalanceEvent[] = filteredData
        let filterOptions = this.displayFilterOptions(data);
        let results = filteredData.length > 0 ? this.displayDataTable(filteredData) : this.displayNoDataMessage();
        let displayEventDetail = this.state.eventDetailComponentIsVisible;

        if (displayEventDetail) {
           if (this.state.displayEventDetailLoadingIcon) {
              return (
                 <div >
                    <EventDetailLoadingIcon></EventDetailLoadingIcon>
                 </div>
              )
           }
           else {
              return (
                 <div id="tableContainer">
                    <br />
                    <Button id="eventDetailBackButton" className="pds-button pds-button-primary"
                       onClick={() => { this.toggleShowModal() }}>
                       <Icon name="arrow-left" /> Back
                    </Button>
                    <EventDetailsModal eventDetails={this.state.eventDetail} eventType={this.state.eventType} eventId={this.state.eventDetailId}></EventDetailsModal>
                 </div>
              )
           }
        }
  
        return (
            <div id="tableContainer">
                {filterOptions}
                {results}
            </div>
        );
    }
}


function mapStateToProps(state: any) {
    const { filteredEvents, events, entriesPerPage } = state.rootReducer.balReducer;
    const totalPages = Math.ceil(filteredEvents.length / entriesPerPage);
    return {
        events,
        currentData: state.rootReducer.balReducer.currentData,
        filteredEvents: state.rootReducer.balReducer.filteredEvents,
        eventTypesSelected: state.rootReducer.balReducer.eventTypesSelected,
        contractId: state.rootReducer.balReducer.contractId,
        enterpriseId: state.rootReducer.balReducer.enterpriseId,
        employeeName: state.rootReducer.balReducer.employeeName,
        accounts: state.rootReducer.balReducer.accounts,
        entriesPerPage: state.rootReducer.balReducer.entriesPerPage,
        totalPages,
        totalPagesUpdated: state.rootReducer.balReducer.totalPagesUpdated,
        currentPage: state.rootReducer.balReducer.currentPage,
        accountsSelected: state.rootReducer.balReducer.accountsSelected,
        eventsSelected: state.rootReducer.balReducer.eventTypesSelected,
        moneyTypesSelected: state.rootReducer.balReducer.moneyTypesSelected,
        moneyTypeIds: state.rootReducer.balReducer.moneyTypes,
        accountIds: state.rootReducer.balReducer.accounts,
        displayEnterpriseId: state.rootReducer.balReducer.displayEnterpriseId,
        displayContractId: state.rootReducer.balReducer.displayContractId,
        displayEmployeeName: state.rootReducer.balReducer.displayEmployeeName

    };
}

export default connect(mapStateToProps)(Pagination);


