import { configureStore, applyMiddleware } from '@reduxjs/toolkit'
import rootReducer from './reducers'
import thunk from "redux-thunk";

const logger = [thunk]

function exampleMiddleware(storeAPI: any) {
   return function wrapDispatch(next: any) {
      return function handleAction(action: any) {
         // Do anything here: pass the action onwards with next(action),
         // or restart the pipeline with storeAPI.dispatch(action)
         // Can also use storeAPI.getState() here

         return next(action)
      }
   }
}

const store = configureStore({
   reducer: {
      rootReducer
   },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
export default store;