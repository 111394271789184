import '@pds/navigationPrimary/navigationPrimary.min.css';
import '@pds-react/core';
import './header.css';

/**
 * This component is mainly for example purposes.
 * Implement the appropriate header from the Principal Design System.
 * See: https://design.principal.com/development/patterns/enterprise-header
 */
export function Header() {
  return (
 
    <div
      className="pds-navigationPrimary pds-navigationPrimary-primary"
    >
        <link href="https://www.principalcdn.com/css/principal-design-system/pds/latest/pds.min.css" media="all" rel="stylesheet" />
      <div className="pds-navigationPrimary-container container-fluid pds-util-padding-horz-24">
        <a
          className="pds-navigationPrimary-skip-to-main pds-link-unstyled"
          href="#main"
        >
          Skip to content
        </a>
        <div id="headerIcon" className="pds-navigationPrimary-logo">
          <a
            data-gtm="navigation-primary"
            className="pds-navigationPrimary-logo-link pds-link-unstyled"
            href="https://www.principal.com"
            aria-label="Principal Homepage"
          >
            <img alt="" className="pds-imgLogoCompanyNameWhiteRetina" />
          </a>
        </div>
      </div>
    
     
    </div>
   
    
  );
}

export default Header;