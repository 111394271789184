import Header from "../header/header";
import Form from "../form/form";
import Page from "../table/pagination";
import Table from "../table/table";
import RestApi from '../../services/rest';
import { useAuth } from 'react-oidc-context';
import { useParams } from 'react-router-dom';
import "./layout.css";
import store from '../../store'
import { useEffect } from "react";
import { hotjar } from 'react-hotjar';
import jwtDecode from 'jwt-decode';



export const Layout = (props: any) => {
   let contractId = props.contractId;
   let enterpriseId = props.enterpriseId;
   let accountId = props.accountId;
   let jwtToken: any;
   let email: any;
   let splitEmail: string;
   let name: string;
   let rest: RestApi = new RestApi();
   //var username : string;

   const auth = useAuth();

   localStorage.setItem("NQ_AZURE_AUTH_TOKEN", auth.user?.access_token!);
   localStorage.setItem("NQ_AZURE_AUTH_TOKEN_EXPIRES", auth.user?.expires_at! + '');

   //manual route
   if (contractId !== null || store.getState().rootReducer.balReducer.contractId !== '') {
      store.dispatch({
         type: "PREPOPULATE_CONTRACT_ID",
         payload: contractId !== null ? contractId : store.getState().rootReducer.balReducer.contractId
      })
   }

   if (enterpriseId !== null || store.getState().rootReducer.balReducer.enterpriseId !== '') {
      store.dispatch({
         type: "PREPOPULATE_ENTPRS_ID",
         payload: enterpriseId !== null ? enterpriseId : store.getState().rootReducer.balReducer.enterpriseId
      })
   }

   if (accountId !== null || store.getState().rootReducer.balReducer.accountsSelected.length !== 0) {
      let accArray = [];
      accArray.push(accountId);
      store.dispatch({
         type: "PREPOPULATE_ACNT_ID",
         payload: accountId !== null ? accArray : store.getState().rootReducer.balReducer.accountsSelected
      })
   }

   useEffect(() => {
      //test
      jwtToken = localStorage.getItem("NQ_AZURE_AUTH_TOKEN")?.toString();
      const decodedToken = jwtDecode(jwtToken) as any;
      const metaTag = document.createElement('meta');
      const script = document.createElement('script');
      if (process.env.REACT_APP_SERVER_ENV === 'development' || process.env.REACT_APP_SERVER_ENV === 'local') {
         email = decodedToken.preferred_username;
         hotjar.initialize({ id: 35121680, sv: 6 });
         script.src = "https://js-cdn.dynatrace.com/jstag/16ab023090d/bf09944nrb/2e8d33cab11f960b_complete.js";
         //script.setAttribute("data-dtconfig", "app=2e8d33cab11f960b|dsss=1|ssc=1|cors=1|featureHash=ICA27NVfhqrux|vcv=2|reportUrl=https://bf09944nrb.bf.dynatrace.com/bf|rdnt=1|uxrgce=1|bp=3|cuc=az5jsoim|mel=100000|dpvc=1|md=mdcc1=a[name=email]@content|ssv=4|lastModification=1688650648160|tp=500,50,0,1|agentUri=/ruxitagentjs_ICA27NVfhqrux_10267230522124059.js");
         script.type = "text/javascript";
         //script.async = true;
         script.crossOrigin = "anonymous";
      }
      else {
         email = decodedToken.unique_name;
         hotjar.initialize({ id: 3427988, sv: 6 });
         script.src = "https://js-cdn.dynatrace.com/jstag/16ab023090d/bf02182chf/a5998cff42b79999_complete.js";
         //script.setAttribute("data-dtconfig", "app=a5998cff42b79999|dsss=1|ssc=1|cors=1|featureHash=ICA27NVfhqrtux|vcv=2|reportUrl=https://bf02182chf.bf.dynatrace.com/bf|rdnt=1|uxrgce=1|bp=3|cuc=ss94q7ea|mel=100000|dpvc=1|md=mdcc1=a[name='email']@content|ssv=4|lastModification=1688740557125|tp=500,50,0,1|agentUri=/ruxitagentjs_ICA27NVfhqrtux_10265230425083909.js");
         script.type = "text/javascript";
         script.crossOrigin = "anonymous";
         //script.async = true;
      }

      splitEmail = email.split('@');
      name = splitEmail[0];
      //console.log(name);
      metaTag.name = 'email';
      let username = ''
      //metaTag.content = name;
      rest.getUser(name).then((response: any) => {
         if (response.data.length !== 0) {
            console.log(response.data[0].userId)
            username = response.data[0].userId;
         }
      }).finally(() => {
         metaTag.content = username;
         if (metaTag.content.length > 0) {
            document.head.appendChild(metaTag);
            document.head.appendChild(script);
         }
         return () => {
            if (metaTag.content.length > 0) {
               document.head.removeChild(metaTag);
               document.head.removeChild(script);
            }
         }
      });

   }, [])

   return (

      <>

         <div>
            <Header></Header>
         </div>
         <div>
            <h1 id="formHeader" className="pds-typography-h1">NQ Balance History Viewer</h1>
            <Form></Form>
            <Table></Table>
         </div>
         <div>
         </div>
      </>
   )
}
