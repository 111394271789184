import React from 'react';
import { connect } from "react-redux";
import store from '../../store';
import NoParticipantMessage from '../message/no-participant-message';
import '../table/table.css'
class ParticipantInformation extends React.Component<any, any> {

   constructor(props: any) {
      super(props);
   }
    render() {
        const displayElement = this.props.employeeName !== '' ? <div id="searchResultsEmployeeInfo">
        <p id="searchFont">
            Contract ID: <b>{this.props.contractId}</b> <br />
            Employee: <b>{this.props.employeeName} ({this.props.enterpriseId})</b> <br />
            Account(s): <b>{this.props.accountsSelected == 0
               ? 'All Accounts'
               : this.props.accountsSelected.map((acc: any) => acc.value).join(', ')
            }</b><br />
         </p>
      </div> : ''
      return (
         <div>
            {displayElement}
         </div>
      )
   };
}

function mapStateToProps(state: any) {
   return {
      contractId: state.rootReducer.balReducer.contractId,
      employeeName: state.rootReducer.balReducer.employeeName,
      accountsSelected: state.rootReducer.balReducer.accountsSelected,
      enterpriseId: state.rootReducer.balReducer.enterpriseId
   }
}

export default connect(mapStateToProps)(ParticipantInformation);