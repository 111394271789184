import React, { useRef } from "react";
import "./event-details.css";
import { connect } from "react-redux";
import RestApi from '../../services/rest';
import NoBalanceEventDetails from "../message/no-balance-event-details";


class EventDetailsModal extends React.Component<any, any> {

   // arrow, widen, footer
   rest: RestApi = new RestApi();

   constructor(props: any) {
      super(props);
   }

   buildEventDetailTable(detailsAsList: any) {
      if (detailsAsList.length == 0) {
         return (
            <div>
               <NoBalanceEventDetails eventId={this.props.eventId}></NoBalanceEventDetails>
            </div>
         )
      }

      return (
         <div id="eventDetailContainer">
            <div className="divider-bottom"></div><br />
            <h3>Event Details</h3>
            <div className="pds-divider"></div>
            <table>
               <tbody>
                  <tr>
                     <td className="event-border" colSpan={1}><b>Event ID: </b>{this.props.eventDetails.eventId}</td>
                     <td className="event-border" colSpan={1}><b>Event Type: </b>{this.props.eventType}</td>
                     <td className="event-border" colSpan={2}><b>Author: </b>{this.props.eventDetails.author}</td>
                     <td className="event-border" colSpan={2}><b>Account ID: </b>{this.props.eventDetails.accountId}</td>
                     <td className="event-border" colSpan={3}><b>Money Type: </b>{this.props.eventDetails.mtId}</td>
                  </tr>
                  
                  <tr>
                     <td className="event-border" colSpan={1}><b>Itransact: </b>{this.props.eventDetails.srceTrnsId}</td>
                     <td className="event-border" colSpan={1}><b>Transaction Type </b>{this.props.eventDetails.transactionType}</td>
                     <td className="event-border" colSpan={2}><b>Effective Date: </b>{this.props.eventDetails.newEffectiveDate.substring(0, 10)}</td>
                     <td className="event-border" colSpan={2}><b>Old Transaction Amount: </b>{Number(this.props.eventDetails.oldTransactionAmount).toFixed(2)}</td>
                     <td className="event-border" colSpan={2}><b>New Transaction Amount: </b>{Number(this.props.eventDetails.newTransactionAmount).toFixed(2)}</td>
                     <td className="event-border" colSpan={3}><b>Timestamp: </b>{this.props.eventDetails.createdDate}</td>

                  </tr>
               </tbody>
            </table>
            <div className="pds-divider divider-bottom"></div><br />

            <div className="pds-table-scrolling pds-locked-header-row pds-table-height">
               <table className="pds-table pds-table-locked-header-row pds-table-hoverable-rows">
                  <colgroup>
                     <col span={1} />
                     <col span={4} className="pds-color-bg-neutral-6" />
                     <col span={4} />
                  </colgroup>
                  <thead>
                     <tr>
                        <th>Fund</th>
                        <th className="pds-color-bg-neutral-6 left-border-darker">Price</th>
                        <th className="pds-color-bg-neutral-6">Dollars</th>
                        <th className="pds-color-bg-neutral-6">Shares</th>
                        <th className="pds-color-bg-neutral-6">End Shares</th>
                        <th className="left-border-darker">Price</th>
                        <th>Dollars</th>
                        <th>Shares</th>
                        <th>End Shares</th>
                     </tr>
                  </thead>
                  <tbody>
                     <tr className="pds-typography-eyebrow-2 pds-color-bg-neutral-5">
                        <td></td>
                        <td className="sub-header left-border-darker" colSpan={4}>
                           <strong>Pre-Event</strong>
                        </td>
                        <td className="sub-header left-border-darker" colSpan={4}>
                           <strong>Post-Event</strong>
                        </td>
                     </tr>
                     {detailsAsList.map((detail: any) => {
                        return (
                           <tr>
                              <td>{detail.nqInvId == undefined ? 'N/A' : detail.nqInvId}</td>
                              <td className="left-border-darker">{detail.oldNav == (undefined || 0) ? 'N/A' : Number(detail.oldNav).toFixed(4)}</td>
                              <td>{detail.oldDollars == undefined ? 'N/A' : '$' + Number(detail.oldDollars).toFixed(2)}</td>
                              <td>{detail.oldShares == undefined ? 'N/A' : Number(detail.oldShares).toFixed(4)}</td>
                              <td>{detail.oldShareBalance == undefined ? 'N/A' : Number(detail.oldShareBalance).toFixed(4)}</td>
                              <td className="left-border-darker">{detail.newNav == undefined ? 'N/A' : Number(detail.newNav).toFixed(4)}</td>
                              <td>{detail.newDollars == undefined ? 'N/A' : '$' + Number(detail.newDollars).toFixed(2)}</td>
                              <td>{detail.newShares == undefined ? 'N/A' : Number(detail.newShares).toFixed(4)}</td>
                              <td>{detail.newShareBalance == undefined ? 'N/A' : Number(detail.newShares).toFixed(4)}</td>
                           </tr>
                        )
                     })}
                  </tbody>
               </table>
            </div >
            <div className="divider-bottom"></div><br />
         </div >

      )
   }

   render() {
      let detailsAsList: any[] = [];
      if(this.props.eventDetails !== null) {
         console.log(this.props.eventDetails.payload);

         Object.entries(this.props.eventDetails.payload).map(([key, value]) => {
            console.log("Key: " + key);
            console.log(value)
            detailsAsList.push(value);
         })
      }

      let eventDetailTable = this.buildEventDetailTable(detailsAsList)!;

      return (
         <div>
            {eventDetailTable}
         </div>
      );
   }
}

function mapStateToProps(state: any) {
   return {
      curEvent: state.rootReducer.balReducer.eventDetails,
      displayModal: state.rootReducer.balReducer.displayEventDetailsModal
   };
};


export default connect(mapStateToProps)(EventDetailsModal);

