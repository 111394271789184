//  TODO: Remove unused cases and variables from initialState.
interface BalanceState {
   contractId: string;
   enterpriseId: string;
   accounts: any[];
   moneyTypes: any[];
   selectedAccount: string;
   eventFilters: any[];
   moneyTypeFilters: any[];
   historicalTimeframe: string;
   startDate: string;
   endDate: string;
   loadingSpinner: boolean;
   loadingTable: boolean;
   disabledSearchButton: boolean;
   isEmptyList: boolean;
   emptyListMessage: string;
   displayDataTable: boolean;
   requiredFieldsIsDisabled: boolean;
   events: any[];
   filteredEvents: any[];
   entriesPerPage: number;
   totalPages: number;
   totalPagesUpdated: number;
   accountsSelected: any[];
   eventTypesSelected: any[];
   moneyTypesSelected: any[];
   employeeName: string;
   currentPage: number;
   currentData: [],
   sortOrder: string;
 }
 
 const initialState: BalanceState = {
   contractId: '',
   enterpriseId: '',
   accounts: [],
   moneyTypes: [],
   selectedAccount: '',
   eventFilters: [],
   moneyTypeFilters: [],
   historicalTimeframe: '',
   startDate: '',
   endDate: '',
   loadingSpinner: false,
   loadingTable: false,
   disabledSearchButton: true,
   isEmptyList: false,
   emptyListMessage: '',
   displayDataTable: false,
   requiredFieldsIsDisabled: false,
   events: [],
   filteredEvents: [],
   entriesPerPage: 10,
   totalPages: 0,
   totalPagesUpdated: 0,
   accountsSelected: [],
   eventTypesSelected: [],
   moneyTypesSelected: [],
   employeeName: '',
   currentPage: 1,
   currentData: [],
   sortOrder: "ascending"
 }
 
 export default function balanceReducer(state = initialState, action: any) {
 
   switch (action.type) {
     case 'SET_EMPLOYEE_DATA': {
       return {
         ...state,
         contractId: action.payload[0],
         enterpriseId: action.payload[1],
       }
     };
 
     case 'UPDATE_CONTRACTID': {
       return {
         ...state,
         contractId: action.payload
       }
     }
 
     case 'UPDATE_ENTERPRISEID': {
       return {
         ...state,
         enterpriseId: action.payload,
       }
     }
 
     case 'DISPLAY_ENTERPRISEID': {
       return {
         ...state,
         displayEnterpriseId: action.payload
       }
     }
 
     case 'DISPLAY_EMPLOYEE_NAME': {
       return {
         ...state,
         displayEmployeeName: action.payload
       }
     }
 
     case 'DISPLAY_CONTRACT_ID': {
       return {
         ...state,
         displayContractId: action.payload
       }
     }
 
     case 'SET_EMPLOYEE_ACCOUNTS': {
       return {
         ...state,
         accounts: action.payload,
         loadingSpinner: false
       }
     }
 
     case 'SET_EMPLOYEE_NAME': {
       return {
         ...state,
         employeeName: action.payload
       }
     }
     case 'SET_CONTRACT_MONEYTYPES': {
       return {
         ...state,
         moneyTypes: action.payload,
         loadingSpinner: false
       }
     }
     // case 'SET_CURRENT_DATA': {
     //   return {
     //     ...state,
     //     currentData: action.payload
     //   }
     // }
 
     case 'SET_FILTERED_EVENTS': {
       return {
         ...state,
         filteredEvents: action.payload
       }
     }
     case 'SET_FILTERED_MONEYTYPE': {
       return {
         ...state,
         money: action.payload
       }
     }
 
     case 'SET_HISTORICAL_TIMEFRAME': {
       return {
         ...state,
         historicalTimeFrame: action.payload
       }
     }
 
     case 'SET_START_DATE': {
       return {
         ...state,
         startDate: action.payload
       }
     }
 
     case 'SET_END_DATE': {
       return {
         ...state,
         endDate: action.payload
       }
     }
 
     case 'ENABLE_LOADING_SPINNER': {
       return {
         ...state,
         loadingSpinner: true
       }
     }
 
     case 'DISABLE_LOADING_SPINNER': {
       return {
         ...state,
         loadingSpinner: false
       }
     }
 
     case 'ENABLE_LOADING_TABLE': {
       return {
         ...state,
         loadingTable: true
       }
     }
 
     case 'DISABLE_LOADING_TABLE': {
       return {
         ...state,
         loadingTable: false
       }
     }
 
     case 'ENABLE_SEARCH_BUTTON': {
       return {
         ...state,
         disabledSearchButton: false
       }
     }
 
     case 'DISABLE_SEARCH_BUTTON': {
       return {
         ...state,
         disabledSearchButton: true
       }
     }
 
     case 'DISABLE_REQUIRED_FIELDS': {
       return {
         ...state,
         requiredFieldsIsDisabled: true
 
       }
     }
 
     case 'SET_EMPTY_LIST_MESSAGE': {
       return {
         ...state,
         isEmptyList: true,
         emptyListMessage: action.payload
       }
     }
 
     case 'SET_NOT_EMPTY_LIST': {
       return {
         ...state,
         isEmptyList: false
       }
     }
 
     case 'SET_BALANCE_EVENTS': {
       return {
         ...state,
         events: action.payload,
         filteredEvents: action.payload,
         displayDataTable: true
       }
     }
 
     case 'SET_ENTRIES_PER_PAGE': {
       return {
         ...state,
         entriesPerPage: action.payload
       }
     }
 
     case 'SET_TOTAL_PAGES': {
       return {
         ...state,
         totalPages: action.payload
       }
     }
 
     case 'SET_UPDATED_TOTAL_PAGES': {
       return {
         ...state,
         totalPagesUpdated: action.payload
       }
     }
 
     case 'SET_ACCOUNT_OPTIONS_SELECTED': {
       return {
         ...state,
         accountsSelected: action.payload,
       }
     }
 
     case 'SET_EVENT_OPTIONS_SELECTED': {
       return {
         ...state,
         eventTypesSelected: action.payload,
       }
     }
 
     case 'SET_MT_OPTIONS_SELECTED': {
       return {
         ...state,
         moneyTypesSelected: action.payload,
       }
     }
 
     case 'SET_SUB_ACCOUNT_RESPONSE': {
       return {
         ...state,
         subAccountResponse: action.payload
       }
     }
 
     case 'SET_EMPLOYEE_NAME': {
       return {
         ...state,
         employeeName: action.payload
       }
     }
 
     case 'CHANGE_PAGE': {
       return {
         ...state,
         currentPage: action.payload
       }
     }
 
     case 'CHANGE_SORT_ORDER':{
       return {
         ...state,
         sortOrder: action.payload
       }
     }
 
     case 'RESET_PAGINATION': {
       return {
         ...state,
         currentPage: 1
       }
     }
 
     case 'PREPOPULATE_CONTRACT_ENTERPRISE_IDS' : {
       //console.log(action.payload)
       return {
         ...state,
         contractId: action.payload[0],
         enterpriseId: action.payload[1]
       }
     }

     case 'PREPOPULATE_CONTRACT_ID' : {
      //console.log(action.payload)
      return {
        ...state,
        contractId: action.payload,
      }
    }

    case 'PREPOPULATE_ENTPRS_ID' : {
      //console.log(action.payload)
      return {
        ...state,
        enterpriseId: action.payload,
      }
    }

    case 'PREPOPULATE_ACNT_ID' : {
      //console.log(action.payload)
      return {
        ...state,
        accountsSelected: action.payload,
      }
    }
 
 
     case 'PREPOPULATE_IDS': {
       //console.log(action.payload)
       return {
         ...state,
         contractId: action.payload[0],
         enterpriseId: action.payload[1],
         accountsSelected: action.payload[2]
       }
     }
 
     case 'RESET_SEARCH': {
       return {
         ...state,
         contractId: '',
         enterpriseId: '',
         accounts: [],
         moneyTypes: [],
         selectedAccount: '',
         eventFilters: [],
         moneyTypeFilters: [],
         historicalTimeframe: '',
         startDate: '',
         endDate: '',
         loadingSpinner: false,
         loadingTable: false,
         disabledSearchButton: true,
         isEmptyList: false,
         emptyListMessage: '',
         displayDataTable: false,
         requiredFieldsIsDisabled: false,
         events: [],
         filteredEvents: [],
         entriesPerPage: 10,
         totalPages: 0,
         accountsSelected: [],
         eventTypesSelected: [],
         moneyTypesSelected: [],
         employeeName: '',
         currentPage: 1
       }
     }
 
     default:
       // If this reducer doesn't recognize the action type, or doesn't
       // care about this specific action, return the existing state unchanged
       return state
   }
 }