import { Link, useLocation, BrowserRouter, Routes, Route } from 'react-router-dom';
import { SPAAuthProvider } from '@principalfinancialgroup/ciam-spa-auth-provider';
import './App.css';
import { Provider } from 'react-redux'
import store from './store';
import { Layout } from './components/layout/layout';

function App() {
  //const clientId = '30bfba00-18a0-4d68-a1dc-7bf4bcafc9d6' PROD
  let clientId = 'e5f162fb-8cda-4b9c-8231-4868ca5b6cba'
  //const redirectUri = 'https://historyviewer.usis-nq-liability.prod.principalaws.com/' PROD
  let redirectUri = 'https://historyviewer.usis-nq-liability.dev.principalaws.com/'
  //const issuer = 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0' PROD
  let issuer = 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0'
  //const postLogourUri = 'https://historyviewer.usis-nq-liability.prod.principalaws.com/' PROD
  let postLogoutUri = 'https://historyviewer.usis-nq-liability.dev.principalaws.com/'

  let scopes = []
  if (process.env.REACT_APP_SERVER_ENV === 'local') {
    clientId = 'e5f162fb-8cda-4b9c-8231-4868ca5b6cba';
    redirectUri = 'http://localhost:3000';
    issuer = 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0'
    postLogoutUri = 'http://localhost:3000';
    scopes.push('api://e5f162fb-8cda-4b9c-8231-4868ca5b6cba/history-viewer-dev-scope')
  }
  else if (process.env.REACT_APP_SERVER_ENV === 'development' || process.env.NODE_ENV === 'development') {
    clientId = 'e5f162fb-8cda-4b9c-8231-4868ca5b6cba';
    redirectUri = 'https://historyviewer.usis-nq-liability.dev.principalaws.com/'
    //redirectUri = 'http://localhost:3000/'
    issuer = 'https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0'
    postLogoutUri = 'https://historyviewer.usis-nq-liability.dev.principalaws.com/'
    scopes.push('api://e5f162fb-8cda-4b9c-8231-4868ca5b6cba/history-viewer-dev-scope')
  }
  else {
    clientId = '30bfba00-18a0-4d68-a1dc-7bf4bcafc9d6';
    redirectUri = 'https://historyviewer.usis-nq-liability.prod.principalaws.com/'
    issuer = "https://login.microsoftonline.com/3bea478c-1684-4a8c-8e85-045ec54ba430/v2.0"
    postLogoutUri = 'https://historyviewer.usis-nq-liability.prod.principalaws.com/'
    scopes.push('api://30bfba00-18a0-4d68-a1dc-7bf4bcafc9d6/history-viewer-prod-scope')
  }

  function GetUrlParams() {
    let query = new URLSearchParams(useLocation().search);
    return (
      <Layout contractId={query.get("contractId")} enterpriseId={query.get("enterpriseId")} accountId={query.get("accountId")} />
    );
  }

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/*"
          element={
            <SPAAuthProvider debug={false} clientId={clientId} redirectUri={redirectUri}
              issuer={issuer} postLogoutUri={postLogoutUri}
              scopes={scopes}>
              <Provider store={store}>
                <GetUrlParams />
              </Provider>
            </SPAAuthProvider>
          }

        />
      </Routes>
    </BrowserRouter>
  );
}

export default App;