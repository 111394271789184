import './table.css';
import { connect } from "react-redux";
import Page from "../table/pagination";
import React from 'react';
import Message from '../message/message';
import LoadingTable from '../loading-table/loading-table';
import ParticipantInformation from '../participant-information/participant-information';
import NoParticipantMessage from '../message/no-participant-message';

class Table extends React.Component<any, any> {

   render() {
      let componentToDisplay;

      if (this.props.filteredEvents.length == 0 && this.props.events.length == 0 && this.props.isEmptyList && this.props.employeeName !== '') {
         componentToDisplay = (
            <div>
               <ParticipantInformation />
               <div />
               <br />
               <hr className="hrStyleNoTable" />
               <br />
               <Message />
            </div>
         )
      }
      else if (this.props.employeeName === '' && this.props.filteredEvents.length == 0 && this.props.events.length == 0 && this.props.isEmptyList) {
         componentToDisplay = (
            <div>
               <br />
               <NoParticipantMessage />
               <br />
               <hr className="hrStyleNoTable" />
            </div>)
      }
      else if (this.props.filteredEvents.length > 0) {
         componentToDisplay = <Page />;
      }
      else if (this.props.loadingTable) {
         componentToDisplay = <LoadingTable rows={5} cols={7} />
      }

      return (
         <div>
            {componentToDisplay}
         </div>
      );
   }
}

function mapStateToProps(state: any) {
   return {
      contractId: state.rootReducer.balReducer.contractId,
      enterpriseId: state.rootReducer.balReducer.enterpriseId,
      accountIds: state.rootReducer.balReducer.accounts,
      moneyTypeIds: state.rootReducer.balReducer.moneyTypes,
      moneyTypeIdFilters: state.rootReducer.balReducer.moneyTypeFilters,
      isEmptyList: state.rootReducer.balReducer.isEmptyList,
      displayDataTable: state.rootReducer.balReducer.displayDataTable,
      startDate: state.rootReducer.balReducer.startDate,
      endDate: state.rootReducer.balReducer.endDate,
      loadingSpinner: state.rootReducer.balReducer.loadingSpinner,
      loadingTable: state.rootReducer.balReducer.loadingTable,
      accountsSelected: state.rootReducer.balReducer.accountsSelected,
      eventsSelected: state.rootReducer.balReducer.eventTypesSelected,
      moneyTypesSelected: state.rootReducer.balReducer.moneyTypesSelected,
      events: state.rootReducer.balReducer.events,
      filteredEvents: state.rootReducer.balReducer.filteredEvents,
      employeeName: state.rootReducer.balReducer.employeeName
   };
}

export default connect(mapStateToProps)(Table);
