import './loading-table.css';
import React from "react";
import { SkeletonLoader } from '@pdsreact/skeleton-loader'


class LoadingTable extends React.Component<any, any> {

   rows: number = 10;
   cols: number = 5;
   headers: string[] = [];

   constructor(props: any) {
      super(props);
      this.rows = props.rows;
      this.cols = props.cols
      this.headers = props.headers;
   }

   render() {
      let colArray: any[] = new Array(this.cols);
      let rowArray: any[] = new Array(this.rows);

      for (var i = 0; i < this.cols; i++) {
         colArray.push(i);
      }

      for (var i = 0; i < this.rows; i++) {
         rowArray.push(i);
      }

      return (
         <div id="loadingTable">
            <table className="pds-table pds-table-bordered">
               <thead>
                  <tr>
                     {this.determineHeaders()}
                  </tr>
               </thead>
               <tbody>
                  {rowArray.map((index: any) => { //create a new TR for each row object
                     return (
                        <tr>
                           {colArray.map((row: any) => {
                              return (
                                 <td>
                                    <SkeletonLoader loaderType="text" />
                                 </td>
                              )
                           })}
                        </tr>
                     )
                  })}
               </tbody>
            </table>
         </div>
      )
   }

   //If headers props are not passed in through the props, the component will default to displaying the 'heading' Skeleton Loader.
   //If headers props are passed through, the component will use the provided headers
   determineHeaders(): any {
      let colArray: any[];

      if (this.headers == undefined || this.headers == null) {
         colArray = Array.from(Array(this.cols).keys());
         return colArray.map(() => {
            return (
               <th>
                  <SkeletonLoader loaderType="heading" />
               </th>
            )
         })
      }
      else {
         colArray = this.headers;
         return colArray.map((index: any) => {
            return (
               <th>
                  {index}
               </th>
            )
         })
      }
   }
}

export default LoadingTable;