import React from "react";
import Alert from "@pds-react/alert";

class NoBalanceEventDetails extends React.Component<any, any> {
    eventId: string;
    constructor(props: any) {
        super(props)
        this.eventId = props.eventId
    }

   render() {
      return (
         <div id="noBalanceEventMessage" style={{ width: '40%', marginLeft: '29%', marginTop:'1%' }}>
            <Alert variant="information" style={{ textAlign: "center" }}>
               <h6>There are no details for event {this.eventId}<br />
               </h6>
            </Alert>
         </div>
      );
   }
}

export default NoBalanceEventDetails;