import { TypographyUtils, Utils } from '../types/utils';

const buildModularUtilClasses = (styles: any, utils: Utils): string => {
  return utils?.map((utilClass) => styles[utilClass]).join(' ');
};

export const buildModularTypographyUtilClasses = (
  styles: any,
  typographyUtils: TypographyUtils
): string => {
  return typographyUtils?.map((utilClass) => styles[utilClass]).join(' ');
};

export default buildModularUtilClasses;
