import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App'
import * as dotenv from 'dotenv';

const root = ReactDOM.createRoot(
   document.getElementById('root') as HTMLElement
);

//console.log(process.env);
root.render(
   <React.StrictMode>
      <head><script type="text/javascript" src="//js-cdn.dynatrace.com/jstag/145e12d594f/ruxitagentjs_2nr_1006400120011129.js" data-dtconfig="tp=500,50,0,0,10"></script></head>
      <App />
   </React.StrictMode>
);

