import React from "react";
import Alert from "@pds-react/alert";

class NoParticipantMessage extends React.Component<any, any> {

   render() {
      return (
         <div id="informationAlert" style={{ width: '80%', marginLeft: '10%' }}>
            <Alert variant="information" style={{ textAlign: "center" }}>
               <h6>There is no participant with these search paramaters.<br />
               </h6>
            </Alert>
         </div>
      );
   }
}

export default NoParticipantMessage;